'use client';

import { X } from 'lucide-react';
import Image from 'next/image';
import * as React from 'react';
import { Drawer as BottomSheetPrimitive } from 'vaul';

import { cn } from '@/lib/utils';

import { Button, ButtonProps } from '../button';
import { useTextVariants } from '../text';

export type BottomSheetProps = React.ComponentProps<
  typeof BottomSheetPrimitive.Root
>;

const BottomSheet = ({
  shouldScaleBackground = true,
  ...props
}: BottomSheetProps) => (
  <BottomSheetPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    {...props}
  />
);
BottomSheet.displayName = 'BottomSheet';

const BottomSheetTrigger = BottomSheetPrimitive.Trigger;

const BottomSheetTriggerButton = Button;

const BottomSheetPortal = BottomSheetPrimitive.Portal;

const BottomSheetAction = React.forwardRef<
  React.ElementRef<typeof Button>,
  Omit<ButtonProps, 'variant'>
>((props, ref) => (
  <Button ref={ref} variant="filled" appearance="primary" {...props} />
));
BottomSheetAction.displayName = 'BottomSheetAction';

const BottomSheetCancel = React.forwardRef<
  React.ElementRef<typeof Button>,
  Omit<ButtonProps, 'size' | 'variant' | 'children' | 'appearance'>
>(({ className, ...props }, ref) => (
  <Button
    {...props}
    ref={ref}
    size="tiny"
    variant="icon"
    aria-label="Cancel"
    appearance="primary"
    className={{
      ...className,
      root: cn(
        'absolute top-6 right-4 bg-background-inset-default rounded-full',
        className?.root,
      ),
    }}
  >
    <X size={16} strokeWidth={3} />
  </Button>
));
BottomSheetCancel.displayName = 'BottomSheetCancel';

const BottomSheetClose = React.forwardRef<
  React.ElementRef<typeof Button>,
  Omit<ButtonProps, 'variant' | 'appearance'>
>((props, ref) => (
  <Button ref={ref} variant="outline" appearance="primary" {...props} />
));
BottomSheetClose.displayName = 'BottomSheetClose';

const BottomSheetOverlay = React.forwardRef<
  React.ElementRef<typeof BottomSheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof BottomSheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <BottomSheetPrimitive.Overlay
    ref={ref}
    className={cn('fixed inset-0 z-50 bg-black/80', className)}
    {...props}
  />
));
BottomSheetOverlay.displayName = BottomSheetPrimitive.Overlay.displayName;

export type BottomSheetContentProps = React.ComponentPropsWithoutRef<
  typeof BottomSheetPrimitive.Content
> & {
  overlayProps?: React.ComponentPropsWithoutRef<typeof BottomSheetOverlay>;
};

const BottomSheetContent = React.forwardRef<
  React.ElementRef<typeof BottomSheetPrimitive.Content>,
  BottomSheetContentProps
>(({ className, children, overlayProps, ...props }, ref) => (
  <BottomSheetPortal>
    <BottomSheetOverlay {...overlayProps} />
    <BottomSheetPrimitive.Content
      ref={ref}
      className={cn(
        'fixed inset-x-0 bottom-0 z-sheet flex h-auto flex-col gap-6 rounded-t-3xl border border-slate-200 bg-white px-6 pb-10 pt-6',
        className,
      )}
      {...props}
    >
      {/* Drag handle */}
      <div
        aria-hidden="true"
        className="absolute left-1/2 top-0 mx-auto -translate-x-1/2 -translate-y-8 p-4"
      >
        <div className="h-1 w-[59px] rounded-2xl bg-white/50" />
      </div>
      {children}
    </BottomSheetPrimitive.Content>
  </BottomSheetPortal>
));
BottomSheetContent.displayName = 'BottomSheetContent';

export type BottomSheetHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  align?: 'center' | 'start' | 'end';
};

const BottomSheetHeader = ({
  className,
  align = 'start',
  ...props
}: BottomSheetHeaderProps) => {
  return (
    <div
      className={cn(
        'flex flex-col gap-5',
        {
          'items-center [&>*]:text-center': align === 'center',
          'items-start [&_*]:text-left': align === 'start',
          'items-end [&_*]:text-right': align === 'end',
        },
        className,
      )}
      {...props}
    />
  );
};
BottomSheetHeader.displayName = 'BottomSheetHeader';

export type BottomSheetFooterProps = React.HTMLAttributes<HTMLDivElement> & {
  align?: 'center' | 'start' | 'end' | 'stretch';
};

const BottomSheetFooter = ({
  className,
  align = 'end',
  ...props
}: BottomSheetFooterProps) => (
  <div
    className={cn(
      'dialog-footer gap-[14px] mt-4 flex',
      {
        'flex-row sm:justify-center sm:[&>*]:flex-grow-0': align === 'center',
        'flex-row sm:justify-start sm:[&>*]:flex-grow-0': align === 'start',
        'flex-row sm:justify-end sm:[&>*]:flex-grow-0': align === 'end',
        'flex-col-reverse sm:[&>*]:flex-1': align === 'stretch',
      },
      className,
    )}
    {...props}
  />
);
BottomSheetFooter.displayName = 'BottomSheetFooter';

const BottomSheetTitle = React.forwardRef<
  React.ElementRef<typeof BottomSheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof BottomSheetPrimitive.Title>
>((props, ref) => {
  const blueprints = useTextVariants({
    weight: 'heading',
    variant: 'subtitle',
    color: 'content-heading-default',
  });

  return (
    <BottomSheetPrimitive.Title ref={ref} {...props} {...blueprints.ownProps} />
  );
});
BottomSheetTitle.displayName = BottomSheetPrimitive.Title.displayName;

const BottomSheetDescription = React.forwardRef<
  React.ElementRef<typeof BottomSheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof BottomSheetPrimitive.Description>
>((props, ref) => {
  const blueprints = useTextVariants({
    weight: 'normal',
    variant: 'body-default',
    color: 'content-body-default',
  });

  return (
    <BottomSheetPrimitive.Description
      ref={ref}
      {...props}
      {...blueprints.ownProps}
    />
  );
});
BottomSheetDescription.displayName =
  BottomSheetPrimitive.Description.displayName;

const BottomSheetCover = React.forwardRef<
  React.ElementRef<'figure'>,
  React.ComponentPropsWithoutRef<'figure'>
>(({ className, children, ...props }, ref) => {
  return (
    <figure
      ref={ref}
      className={cn(
        'w-full aspect-[2/1] rounded-3xl overflow-hidden object-cover [&>img]:w-full [&>img]:h-full [&>img]:object-cover',
        className,
      )}
      {...props}
    >
      {children || (
        <Image
          alt=""
          src="/assets/images/checker.png"
          width={480}
          height={240}
        />
      )}
    </figure>
  );
});
BottomSheetCover.displayName = 'BottomSheetCover';

const useBottomSheet = (defaultOpen?: boolean) => {
  const [open, onOpenChange] = React.useState(defaultOpen ?? false);

  return {
    open,
    onOpenChange,
    onOpen: () => onOpenChange(true),
    onClose: () => onOpenChange(false),
  };
};

export {
  BottomSheet,
  BottomSheetPortal,
  BottomSheetOverlay,
  BottomSheetTrigger,
  BottomSheetTriggerButton,
  BottomSheetCancel,
  BottomSheetAction,
  BottomSheetClose,
  BottomSheetContent,
  BottomSheetHeader,
  BottomSheetFooter,
  BottomSheetTitle,
  BottomSheetDescription,
  BottomSheetCover,
  useBottomSheet,
};
