import { Text } from '../text';
import {
  Dialog,
  DialogAction,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  useDialog,
} from './dialog';

export function DialogDemo() {
  const { open, onOpenChange, onOpen, onClose } = useDialog();

  return (
    <>
      <Text variant="header-default">Dialog demo</Text>
      <DialogTrigger onClick={onOpen}>Open</DialogTrigger>
      <Dialog {...{ open, onOpenChange }}>
        <DialogContent>
          <DialogBody>
            <DialogHeader>
              <DialogTitle>Are you absolutely sure?</DialogTitle>
              <DialogDescription>
                This action cannot be undone. This will permanently delete your
                account and remove your data from our servers.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <DialogClose onClick={onClose}>Cancel</DialogClose>
              <DialogAction>Proceed</DialogAction>
            </DialogFooter>
          </DialogBody>
        </DialogContent>
      </Dialog>
    </>
  );
}
