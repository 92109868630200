import { Text } from '../text';
import {
  BottomSheet,
  BottomSheetAction,
  BottomSheetClose,
  BottomSheetContent,
  BottomSheetDescription,
  BottomSheetFooter,
  BottomSheetHeader,
  BottomSheetTitle,
  BottomSheetTriggerButton,
  useBottomSheet,
} from './bottom-sheet';

export function BottomSheetDemo() {
  const { open, onOpenChange, onOpen, onClose } = useBottomSheet(false);

  return (
    <>
      <Text variant="header-default">Bottom sheet demo</Text>
      <BottomSheetTriggerButton onClick={onOpen}>Open</BottomSheetTriggerButton>
      <BottomSheet {...{ open, onOpenChange }}>
        <BottomSheetContent>
          <BottomSheetHeader>
            <BottomSheetTitle>Are you absolutely sure?</BottomSheetTitle>
            <BottomSheetDescription>
              This action cannot be undone.
            </BottomSheetDescription>
          </BottomSheetHeader>
          <BottomSheetFooter>
            <BottomSheetClose onClick={onClose}>Cancel</BottomSheetClose>
            <BottomSheetAction>Submit</BottomSheetAction>
          </BottomSheetFooter>
        </BottomSheetContent>
      </BottomSheet>
    </>
  );
}
