'use client';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import Image from 'next/image';
import * as React from 'react';

import { cn } from '@/lib/utils';

import { useBottomSheet } from '../bottom-sheet';
import { Button, ButtonProps } from '../button';
import { useTextVariants } from '../text';

const Dialog = DialogPrimitive.Root;
export type DialogProps = React.ComponentProps<typeof Dialog>;

const DialogTrigger = Button;

const DialogPortal = DialogPrimitive.Portal;

export type DialogActionProps = Omit<ButtonProps, 'variant'>;

const DialogAction = React.forwardRef<
  React.ElementRef<typeof Button>,
  DialogActionProps
>((props, ref) => (
  <Button ref={ref} variant="filled" appearance="primary" {...props} />
));
DialogAction.displayName = 'DialogAction';

export type DialogCloseProps = ButtonProps;

const DialogClose = React.forwardRef<
  React.ElementRef<typeof Button>,
  DialogCloseProps
>(({ variant = 'outline', appearance = 'primary', ...props }, ref) => (
  <Button ref={ref} variant={variant} appearance={appearance} {...props} />
));
DialogClose.displayName = 'DialogClose';

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/80',
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

export type DialogSize = 'small' | 'medium' | 'large' | 'auto';

export type DialogContentProps = React.ComponentPropsWithoutRef<
  typeof DialogPrimitive.Content
> & {
  size?: DialogSize;
  overlayProps?: React.ComponentProps<typeof DialogOverlay>;
};

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ className, children, overlayProps, size = 'auto', ...props }, ref) => {
  const sizeClassName = {
    small: 'max-w-[340px] [&_.dialog-footer]:!flex-col-reverse',
    medium: 'max-w-[440px]',
    large: 'max-w-[540px]',
    auto: 'max-w-[340px] sm:max-w-[440px] md:max-w-[540px]',
  }[size];

  return (
    <DialogPortal>
      <DialogOverlay {...overlayProps} />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-[50%] top-[50%] z-dialog w-full translate-x-[-50%] translate-y-[-50%] rounded-units-unit24 border border-slate-200 bg-white shadow-lg duration-200 overflow-hidden',
          sizeClassName,
          className,
        )}
        {...props}
      >
        {children}
      </DialogPrimitive.Content>
    </DialogPortal>
  );
});
DialogContent.displayName = DialogPrimitive.Content.displayName;

export type DialogHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  align?: 'center' | 'start' | 'end';
};

const DialogHeader = ({
  className,
  align = 'start',
  ...props
}: DialogHeaderProps) => {
  return (
    <div
      className={cn(
        'flex flex-col gap-3',
        {
          'items-center [&>*]:text-center': align === 'center',
          'items-start [&_*]:text-left': align === 'start',
          'items-end [&_*]:text-right': align === 'end',
        },
        className,
      )}
      {...props}
    />
  );
};
DialogHeader.displayName = 'DialogHeader';

export type DialogBodyProps = React.ComponentPropsWithoutRef<'div'>;

const DialogBody = React.forwardRef<React.ElementRef<'div'>, DialogBodyProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('grid gap-[18px] p-6', className)}
        {...props}
      >
        {children}
      </div>
    );
  },
);
DialogBody.displayName = 'DialogBody';

export type DialogFooterProps = React.HTMLAttributes<HTMLDivElement> & {
  align?: 'center' | 'start' | 'end' | 'stretch';
};

const DialogFooter = ({
  className,
  align = 'end',
  ...props
}: DialogFooterProps) => (
  <div
    className={cn(
      'dialog-footer sm:gap-[14px][&>*]:flex-1 flex-col-reverse mt-4 flex gap-3 sm:flex-row',
      {
        'sm:justify-center sm:[&>*]:flex-grow-0': align === 'center',
        'sm:justify-start sm:[&>*]:flex-grow-0': align === 'start',
        'sm:justify-end sm:[&>*]:flex-grow-0': align === 'end',
        'sm:[&>*]:flex-1': align === 'stretch',
      },
      className,
    )}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>((props, ref) => {
  const blueprints = useTextVariants({
    weight: 'heading',
    variant: 'header-small',
    color: 'content-heading-default',
  });

  return (
    <DialogPrimitive.Title ref={ref} {...props} {...blueprints.ownProps} />
  );
});
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => {
  const blueprints = useTextVariants({
    weight: 'normal',
    variant: 'body-default',
    color: 'content-body-default',
  });

  return (
    <DialogPrimitive.Description
      ref={ref}
      {...props}
      {...blueprints.ownProps}
      className={className}
    />
  );
});
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export interface DialogIconProps extends React.HTMLAttributes<HTMLSpanElement> {
  appearance?: 'primary' | 'danger' | 'success' | 'warning';
}

const DialogIcon = React.forwardRef<HTMLSpanElement, DialogIconProps>(
  ({ className, appearance = 'primary', ...props }, ref) => {
    const classNames = {
      primary:
        '[&>svg]:text-feedback-information-ghost-text bg-feedback-information-ghost-default',
      danger:
        '[&>svg]:text-feedback-danger-ghost-text bg-feedback-danger-ghost-default',
      success:
        '[&>svg]:text-feedback-success-ghost-text bg-feedback-success-ghost-default',
      warning:
        '[&>svg]:text-feedback-warning-ghost-text bg-feedback-warning-ghost-default',
    }[appearance];

    return (
      <span
        ref={ref}
        className={cn(
          'inline-grid h-12 w-12 place-items-center rounded-full [&>svg]:h-6 [&>svg]:w-6',
          classNames,
          className,
        )}
        {...props}
      />
    );
  },
);
DialogIcon.displayName = 'DialogIcon';

export type DialogCoverProps = React.ComponentPropsWithoutRef<'figure'>;

const DialogCover = React.forwardRef<
  React.ElementRef<'figure'>,
  DialogCoverProps
>(({ className, children, ...props }, ref) => {
  return (
    <figure
      ref={ref}
      className={cn(
        'w-full aspect-[2/1] overflow-hidden object-cover [&>img]:w-full [&>img]:h-full [&>img]:object-cover',
        className,
      )}
      {...props}
    >
      {children || (
        <Image
          alt=""
          src="/assets/images/checker.png"
          width={480}
          height={240}
        />
      )}
    </figure>
  );
});
DialogCover.displayName = 'DialogCover';

const useDialog = useBottomSheet;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogAction,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  DialogIcon,
  DialogCover,
  DialogDescription,
  useDialog,
};
