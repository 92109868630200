import { SVGProps } from 'react';

function SupportPageIllustration(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="1099"
      height="304"
      viewBox="0 0 1099 304"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M541.398 10.6093V270.927H649.394L645.617 260.8H632.261L541.398 10.6093Z"
        fill="#AEDFEF"
      />
      <path
        d="M169.844 71.2782L168.826 270.927H331.918V0L169.844 71.2782Z"
        fill="#AEDFEF"
      />
      <path
        d="M173.955 60.8022C173.955 60.8022 193.379 90.2592 206.841 92.8311C220.304 95.4031 258.723 149.83 258.723 149.83L276.017 137.023C276.017 137.023 237.584 78.7389 220.291 75.5374C202.997 72.3358 190.15 53.6891 190.15 53.6891L173.955 60.8156V60.8022Z"
        fill="white"
      />
      <path
        d="M185.904 44.0978C185.904 44.0978 202.877 68.1028 216.286 70.8489C243.948 76.5286 276.754 131.612 276.754 131.612L283.17 129.683C283.17 129.683 244.738 71.3981 227.444 68.1965C210.15 64.995 195.857 39.8246 195.857 39.8246L185.904 44.0978Z"
        fill="white"
      />
      <path
        d="M503.488 111.598C503.488 111.598 533.066 156.434 553.534 160.332C574.003 164.23 632.488 247.082 632.488 247.082L658.811 227.591C658.811 227.591 600.325 138.885 574.003 134.009C547.681 129.133 528.136 100.748 528.136 100.748L503.488 111.585V111.598Z"
        fill="white"
      />
      <path
        d="M521.666 86.1735C521.666 86.1735 547.493 122.703 567.908 126.896C609.997 135.536 659.936 219.38 659.936 219.38L669.688 216.446C669.688 216.446 611.203 127.74 584.88 122.864C558.558 117.988 536.816 79.6766 536.816 79.6766L521.666 86.1735Z"
        fill="white"
      />
      <mask
        id="mask0_0_1"
        maskUnits="userSpaceOnUse"
        x="0"
        y="5"
        width="1099"
        height="299"
      >
        <path
          d="M1071.65 5.27715H26.7913C11.9949 5.27715 0 17.272 0 32.0684V277.209C0 292.005 11.9949 304 26.7913 304H1071.65C1086.45 304 1098.44 292.005 1098.44 277.209V32.0684C1098.44 17.272 1086.45 5.27715 1071.65 5.27715Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_1)">
        <path
          d="M130.448 50.2731L86.2695 42.4098"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M452.601 273.913C452.601 273.913 489.519 187.686 460.169 171.852C430.82 156.018 345.449 164.886 345.449 164.886V277.611L452.601 273.927V273.913Z"
          fill="white"
        />
        <path
          d="M447.026 174.638C447.026 174.638 469.611 180.814 433.697 263.759"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M457.475 261.362C466.424 236.861 482.445 183.895 460.168 171.946C449.13 165.971 430.215 163.587 410.912 162.783"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1096.26 270.926H-2.67773V323.196H1096.26V270.926Z"
          fill="white"
        />
        <path
          d="M1096.26 270.926H-2.67773V323.196H1096.26V270.926Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M433.699 154.022C451.81 149.843 465.433 102.342 451.904 96.6622C438.374 90.9824 371.811 99.0466 359.875 106.227C347.94 113.393 335.402 155.817 358.281 156.608C381.161 157.398 422.152 156.608 433.699 154.022Z"
          fill="white"
        />
        <path
          d="M405.139 156.42C417.677 155.925 428.822 155.228 433.698 154.13C451.809 149.95 465.433 102.449 451.903 96.7694C438.374 91.0896 404.241 95.7781 379.674 100.547"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M442.662 100.453C444.349 102.945 437.692 126.548 430.525 135.108C423.358 143.668 424.551 138.39 418.482 139.595"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M345.247 105.034C364.751 95.6709 374.999 96.8631 379.379 107.727C379.379 107.727 400.37 105.731 394.396 124.351C394.396 124.351 412.011 122.864 407.028 143.172C407.028 143.172 412.895 144.86 412.895 140.278C412.895 140.278 413.886 154.92 394.891 154.022C394.891 154.022 422.058 171.852 398.964 178.711C375.776 185.676 245.342 187.378 236.89 174.035C228.33 160.693 236.394 158.001 236.394 158.001C236.394 158.001 228.531 154.223 229.026 147.043C229.723 137.184 233.501 130.808 243.052 134.8C243.052 134.8 238.47 128.825 246.441 124.445C254.411 120.064 253.701 121.056 253.701 121.056C253.701 121.056 250.125 114.184 260.761 113.393C276.782 112.201 331.905 103.628 331.905 103.628C331.905 103.628 343.948 93.7688 345.542 105.021H345.247V105.034Z"
          fill="#010205"
        />
        <path
          d="M50.1563 286.063H32.541C32.541 286.063 32.541 292.038 33.3314 294.235C33.3314 294.235 40.0962 296.82 50.1429 296.82C60.1896 296.82 66.9544 294.235 66.9544 294.235C67.7448 292.038 67.7448 286.063 67.7448 286.063H50.1295H50.1563Z"
          fill="white"
        />
        <path
          d="M32.541 286.063C32.541 286.063 32.541 292.038 33.3314 294.235C33.3314 294.235 40.0962 296.82 50.1429 296.82C60.1896 296.82 66.9544 294.235 66.9544 294.235C67.7448 292.038 67.7448 286.063 67.7448 286.063"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6267 246.238C11.255 244.55 6.17809 236.379 9.66096 234.182C13.1438 231.998 36.2245 241.054 38.3142 246.238C40.404 251.409 39.5065 257.196 36.1307 256.392C32.7416 255.588 31.4557 250.819 16.7339 246.238H16.6401H16.6267Z"
          fill="#A2BA60"
        />
        <path
          d="M25.7764 249.815C23.4858 248.622 20.4986 247.43 16.721 246.225C12.9434 245.032 6.27238 236.365 9.75525 234.168C13.2381 231.985 18.9045 234.972 25.7764 237.959"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3535 236.874C12.3535 236.874 24.9856 241.054 31.2548 246.439"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M20.3086 237.866C20.3086 237.866 22.8002 240.049 23.7915 241.643"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M17.5234 243.345C17.5234 243.345 22.3057 244.443 27.5702 243.947"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M31.1463 249.52C29.3513 245.541 25.3728 232.695 28.8557 229.4C32.3385 226.118 50.7441 241.844 52.8339 249.52C54.9236 257.182 54.0261 265.956 50.6504 264.751C47.2613 263.559 35.232 258.281 31.2535 249.52H31.1597H31.1463Z"
          fill="#A2BA60"
        />
        <path
          d="M31.1463 249.52C29.3513 245.541 25.3728 232.695 28.8557 229.4C32.3385 226.118 34.7229 230.096 39.3043 233.686"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.8418 232.99C31.8418 232.99 47.6621 252.413 50.944 261.67"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M40.6973 238.964V244.738"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M33.2363 245.836C33.2363 245.836 40.2958 250.819 45.0781 251.114"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M41.5968 234.289C38.8105 224.524 37.2164 203.519 45.0797 203.519C52.9429 203.519 62.9897 223.238 63.2844 235.28C63.5791 247.323 62.2931 258.374 58.703 258.374C53.6261 258.374 45.3744 247.618 41.6906 234.383L41.5968 234.289Z"
          fill="#A2BA60"
        />
        <path
          d="M47.1694 247.524C44.9859 243.746 42.99 239.165 41.5968 234.276C38.8105 224.51 37.2164 203.506 45.0797 203.506C52.9429 203.506 61.1009 219.929 62.9897 231.985"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.8809 209.788C45.8809 209.788 54.9363 240.263 56.6242 255.2"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M42.5996 220.344C42.5996 220.344 46.4843 226.118 51.3604 229.909"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M51.6405 214.571C51.6405 214.571 50.743 218.656 49.457 222.541"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M58.3135 229.802C58.3135 229.802 55.7281 235.977 53.5312 238.656"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.5291 247.122C75.217 243.438 78.9008 231.583 75.6189 228.502C72.3369 225.421 55.4182 239.955 53.5294 247.122C51.6406 254.289 52.431 262.259 55.5254 261.268C58.6064 260.277 69.7516 255.293 73.5291 247.122Z"
          fill="#A2BA60"
        />
        <path
          d="M73.5435 247.122C75.2314 243.438 78.9152 231.583 75.6333 228.502C72.3513 225.421 55.4326 239.955 53.5438 247.122C51.6551 254.289 53.3429 248.02 53.2491 248.421"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.4461 231.891C72.4461 231.891 62.4931 244.939 55.4336 258.374"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.4377 237.973C73.4377 237.973 70.6514 239.661 65.6816 241.456"
          stroke="#6D7A3B"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M27.2773 252.507C27.2773 252.507 27.4783 277.396 36.5337 287.255C36.5337 287.255 41.6107 289.452 50.1705 289.452C58.7303 289.452 63.8073 287.255 63.8073 287.255C72.8627 277.49 73.0637 252.507 73.0637 252.507H27.2773Z"
          fill="white"
        />
        <path
          d="M27.2773 252.507C27.2773 252.507 27.4783 277.396 36.5337 287.255C36.5337 287.255 41.6107 289.452 50.1705 289.452C58.7303 289.452 63.8073 287.255 63.8073 287.255C72.8627 277.49 73.0637 252.507 73.0637 252.507H27.2773Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M315.296 297.717C307.634 299.111 284.647 299.405 272.819 298.615C260.977 297.811 261.874 294.73 264.955 291.247C268.036 287.764 293.81 278.696 313.314 285.876C332.818 293.056 328.035 295.44 315.31 297.731L315.296 297.717Z"
          fill="white"
        />
        <path
          d="M315.296 297.717C307.634 299.111 284.647 299.405 272.819 298.615C260.977 297.811 261.874 294.73 264.955 291.247C268.036 287.764 293.81 278.696 313.314 285.876C332.818 293.056 328.035 295.44 315.31 297.731L315.296 297.717Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M140.401 290.444L139.102 282.179L183.079 275.909L287.847 279.593L234.72 299.405L140.401 290.444Z"
          fill="white"
        />
        <path
          d="M152.137 283.277L233.221 290.444"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M252.831 292.627L234.72 299.392L140.401 290.43L139.102 282.165L183.079 275.896L265.664 278.789"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M337.691 157.318C328.73 157.117 270.137 175.442 268.436 176.139C266.748 176.835 246.253 191.57 236.594 204.618C226.95 217.665 230.7 270.444 230.7 270.444L382.46 270.337C382.46 270.337 384.75 188.583 374.596 176.246C364.442 163.895 337.785 157.331 337.785 157.331H337.691V157.318Z"
          fill="white"
        />
        <path
          d="M239.986 200.331C238.686 201.724 237.494 203.225 236.503 204.618C226.844 217.665 244.594 270.859 244.594 270.859L382.368 270.337C382.368 270.337 384.659 188.583 374.505 176.246C364.351 163.895 337.693 157.331 337.693 157.331C328.732 157.13 270.139 175.455 268.438 176.152"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M337.69 169.762C337.69 169.762 321.575 195.254 295.4 206.908"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.984 173.942C258.39 180.814 254.411 183.198 250.433 185.984C249.04 186.976 247.553 187.98 246.253 189.373C245.262 190.365 244.458 192.963 243.762 196.741C240.48 212.468 235.55 270.417 230.701 270.431C205.598 270.444 179.878 270.631 177.788 244.336C176.301 224.617 202.959 186.078 216.595 167.86C218.591 165.275 220.172 163.078 221.471 161.39C223.159 159.193 224.164 157.907 224.164 157.907C220.48 149.24 229.04 150.138 229.04 150.138C240.775 151.035 262.368 164.578 260.078 173.942H259.984Z"
          fill="white"
        />
        <path
          d="M242.971 200.023C241.082 210.472 239.086 226.412 235.014 238.562"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M250.328 185.984C248.935 186.976 247.448 187.98 246.148 189.373C245.157 190.365 244.353 192.963 243.657 196.741H243.563C243.563 196.741 224.059 196.044 216.504 167.86C218.5 165.275 220.081 163.078 221.38 161.39C223.175 167.86 230.636 189.963 250.435 185.984H250.341H250.328Z"
          fill="#010205"
        />
        <path
          d="M258.591 173.647C263.373 162.796 234.412 148.959 228.839 150.044C223.266 151.142 247.539 198.831 258.591 173.647Z"
          fill="#010205"
        />
        <path
          d="M250.432 170.352C250.432 170.352 259.38 151.129 257.103 143.668C254.813 136.206 248.342 121.766 245.061 121.658C241.779 121.551 241.779 127.633 246.159 133.406C246.159 133.406 231.236 121.364 220.788 120.265C210.339 119.167 216.313 123.159 216.313 123.159C216.313 123.159 211.437 121.364 208.062 123.159C204.686 124.954 206.963 125.543 210.553 127.137C210.553 127.137 206.575 126.535 206.575 129.522C206.575 132.509 213.045 134.706 213.045 134.706C213.045 134.706 208.263 136.005 211.853 138.792C215.429 141.578 225.878 145.958 229.066 150.138C232.254 154.317 231.25 157.612 234.639 161.792C242.502 171.651 250.553 170.352 250.553 170.352H250.459H250.432Z"
          fill="#9D5B3A"
        />
        <path
          d="M231.318 141.578C231.318 141.578 225.544 137.09 216.797 135.108"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M214.117 128.142C219.489 129.937 225.758 130.834 234.519 137.104"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M219.289 124.15C224.071 125.945 229.738 127.539 237.896 133.406"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M241.271 145.168C241.271 145.168 238.686 138.792 246.147 133.313"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M334.209 80.0382C319.876 77.7475 305.154 82.4226 308.543 104.137C311.932 125.851 342.273 120.761 345.461 103.735C348.649 86.7092 339.795 80.9357 334.222 79.931V80.0248L334.209 80.0382Z"
          fill="white"
        />
        <path
          d="M334.209 80.0382C319.876 77.7475 305.154 82.4226 308.543 104.137C311.932 125.851 342.273 120.761 345.461 103.735C348.649 86.7092 339.795 80.9357 334.222 79.931V80.0248L334.209 80.0382Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M314.211 91.1968C314.211 91.1968 308.049 112.804 327.152 114.693C346.254 116.582 345.557 103.842 345.557 103.842"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M335.294 81.2304C335.294 81.2304 335.589 65.6915 335.294 63.307C335 60.9226 327.927 58.3239 327.927 58.3239L327.029 81.6189L335.281 81.217L335.294 81.2304Z"
          fill="white"
        />
        <path
          d="M335.294 81.2304C335.294 81.2304 335.589 65.6915 335.294 63.307C335 60.9226 327.927 58.3239 327.927 58.3239L327.029 81.6189L335.281 81.217L335.294 81.2304Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M335.295 81.2304C327.338 79.8373 319.18 82.6236 321.068 95.5638C322.957 108.41 339.769 105.423 341.564 95.3628C343.359 85.3027 338.376 81.8198 335.295 81.3242V81.2304Z"
          fill="white"
        />
        <path
          d="M335.295 81.2304C327.338 79.8373 319.18 82.6236 321.068 95.5638C322.957 108.41 339.769 105.423 341.564 95.3628C343.359 85.3027 338.376 81.8198 335.295 81.3242V81.2304Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M325.945 91.3977C325.945 91.3977 323.949 101.257 333.004 101.953C342.06 102.65 341.564 95.2824 341.564 95.2824"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M337.693 159.608C337.693 159.608 298.886 207.605 259.69 202.916C258.994 202.916 258.203 202.716 257.507 202.622C246.763 200.733 262.088 186.386 271.237 176.339L274.024 174.652L289.241 165.69C289.241 164.592 289.442 163.399 289.442 162.207C289.844 153.245 289.737 140.895 288.946 138.912C286.656 132.536 304.365 126.668 311.933 123.976C314.72 122.985 320.091 117.305 323.574 127.459C325.664 133.527 321.779 156.032 337.707 159.622L337.693 159.608Z"
          fill="#9D5B3A"
        />
        <path
          d="M288.639 180.318C288.639 180.318 290.327 162.796 288.84 138.899"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M323.561 127.445C322.463 140.694 303.267 166.574 289.335 169.762C289.831 166.775 289.737 164.283 289.429 162.194C289.831 153.232 289.724 140.881 288.933 138.899C286.643 132.522 304.352 126.655 311.92 123.963C314.707 122.971 320.078 117.292 323.561 127.445Z"
          fill="#010205"
        />
        <path
          d="M327.433 145.463C327.138 145.061 326.241 144.672 325.638 145.168L325.933 146.668C320.856 151.249 303.549 165.583 291.506 159.715L292.899 163.6C292.899 163.6 296.181 167.284 306.924 163.6C321.847 158.617 329.215 149.066 329.215 149.066L327.42 145.382V145.476L327.433 145.463Z"
          fill="#010205"
        />
        <path
          d="M296.099 167.766C297.693 167.766 298.979 166.48 298.979 164.873C298.979 163.265 297.693 161.993 296.099 161.993C294.505 161.993 293.219 163.279 293.219 164.873C293.219 166.467 294.505 167.766 296.099 167.766Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M295.297 167.271C296.449 167.271 297.386 166.333 297.386 165.181C297.386 164.029 296.449 163.091 295.297 163.091C294.145 163.091 293.207 164.029 293.207 165.181C293.207 166.333 294.145 167.271 295.297 167.271Z"
          fill="white"
        />
        <path
          d="M259.677 202.916C258.98 202.916 258.19 202.715 257.493 202.622C246.75 200.733 262.074 186.386 271.224 176.339L274.01 174.652C273.313 175.455 253.019 199.742 259.677 202.836V202.93V202.916Z"
          fill="#010205"
        />
        <path
          d="M412.307 283.866C403.546 293.123 379.769 294.824 359.876 294.422C356.393 294.422 352.91 294.221 349.722 294.02C349.026 294.02 348.329 294.02 347.632 293.913C346.44 293.913 345.141 293.712 343.949 293.618C341.859 293.417 339.769 293.123 337.773 292.828C322.047 290.336 308.919 284.268 310.017 271.623C310.017 270.926 310.218 270.23 310.312 269.533C310.607 268.14 311.009 266.747 311.411 265.648C311.612 265.045 311.906 264.456 312.107 263.96C313.005 262.072 314.197 260.477 315.389 259.178C321.457 252.909 330.312 253.606 330.312 253.606C330.312 253.606 330.312 249.225 341.457 246.934C333.996 212.481 349.026 157.224 374.397 176.433C392.401 190.07 422.554 273.123 412.106 283.973L412.307 283.88V283.866Z"
          fill="white"
        />
        <path
          d="M353.002 193.164C356.485 187.485 360.959 182.408 366.732 178.63"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M341.565 246.734C341.565 246.734 340.667 232.695 344.056 216.861"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M370.62 231.396C370.62 231.396 358.081 230.994 355.402 243.05"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M341.564 246.734C363.453 243.84 389.119 255.602 389.119 255.602"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M359.876 294.328C356.393 294.328 352.911 294.127 349.722 293.926C349.026 293.926 348.329 293.926 347.633 293.833C347.137 284.965 342.06 267.845 312.107 263.759C313.005 261.871 314.197 260.277 315.389 258.977C317.68 259.272 362.06 265.059 359.863 294.328H359.876Z"
          fill="#010205"
        />
        <path
          d="M343.947 293.525C341.857 293.324 339.767 293.029 337.771 292.721C337.57 288.943 336.285 283.665 331.904 279.673C328.127 276.284 318.468 270.913 310.016 271.408C310.016 270.712 310.217 270.015 310.31 269.319C310.605 267.926 311.007 266.532 311.409 265.434C317.075 266.532 334.396 268.622 341.361 282.661C348.327 296.699 343.545 291.221 343.947 293.511V293.525Z"
          fill="#010205"
        />
        <path
          d="M341.563 246.734C330.418 249.024 330.418 253.405 330.418 253.405C323.948 252.011 310.218 258.883 310.124 271.422C307.94 302.982 400.665 297.905 412.306 283.773C422.647 272.922 392.601 189.869 374.597 176.232C374.597 176.232 371.516 172.95 370.123 171.852"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M286.843 275.213C279.878 276.512 258.779 290.149 263.856 291.046C268.933 291.944 273.903 287.764 273.903 287.764C273.903 287.764 266.241 295.333 270.018 296.632C273.796 297.932 280.561 292.346 280.561 292.346C280.561 292.346 276.877 296.726 279.074 297.329C284.245 298.923 289.616 294.435 289.616 294.435C289.616 294.435 286.736 297.329 289.616 297.624C295.778 298.32 302.556 293.444 302.556 293.444C302.556 293.444 320.466 294.435 331.906 291.555C343.346 288.675 322.757 268.85 286.937 275.32L286.843 275.226V275.213Z"
          fill="#9D5B3A"
        />
        <path
          d="M289.723 277.691C289.723 277.691 281.86 281.87 276.395 285.862"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M296.503 279.687C296.503 279.687 290.327 282.969 283.469 289.345"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.971 284.067C299.971 284.067 295.791 287.456 292.309 291.234"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.417 106.334L232.229 259.58L223.173 263.759L90.449 261.375L60.6973 147.365L184.473 106.24L195.417 106.334Z"
          fill="white"
        />
        <path
          d="M195.417 106.334L232.228 259.58L223.173 263.759L90.449 261.375L60.6973 147.365L184.473 106.24L195.417 106.334Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M171.639 120.372L70.1536 154.13L61.5 162.395L85.0763 252.909L190.446 254.704L203.48 249.627L171.639 120.372Z"
          fill="white"
        />
        <path
          d="M61.5 162.395L159.703 129.736L190.446 254.704L85.0763 252.909L61.5 162.395Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M171.641 120.372L159.705 129.736L190.448 254.704L203.482 249.627L171.641 120.372Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.1536 154.13L61.5 162.395L159.703 129.736L171.639 120.372L70.1536 154.13Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M187.459 118.577L218.698 248.328"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.1909 297.115C81.1909 297.115 98.699 246.734 110.045 227.109L150.138 224.912L151.826 235.964C151.826 235.964 133.916 268.622 127.245 297.101L176.595 297.503V300.986L142.664 312.037L67.3398 308.555V299.392L81.2713 297.007L81.1775 297.101L81.1909 297.115Z"
          fill="white"
        />
        <path
          d="M114.723 304.683C114.723 304.683 123.483 270.43 144.273 231.396"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M167.554 299.901L142.973 306.666"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M151.748 235.977C151.748 235.977 133.838 268.636 127.167 297.115L176.517 297.517V300.999L142.585 312.051L67.2617 308.568V299.405L81.1932 297.021C81.1932 297.021 94.4281 258.977 105.265 236.178"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1095.07 -179.65L1248.6 279.888L1211.18 292.332L656.99 285.259L533.014 -56.4768L1049.2 -179.851L1095.07 -179.65Z"
          fill="white"
        />
        <path
          d="M1095.07 -179.65L1248.6 279.888L1211.18 292.332L656.99 285.259L533.014 -56.4768L1049.2 -179.851L1095.07 -179.65Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M995.968 -137.534L572.318 -36.2628L536.002 -11.6684L634.406 259.674L1074.48 265.354L1128.91 250.016L995.982 -137.52L995.968 -137.534Z"
          fill="white"
        />
        <path
          d="M536.002 -11.6684L945.828 -109.55L1074.48 265.354L634.406 259.674L536.002 -11.6684Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M995.968 -137.534L945.828 -109.55L1074.48 265.354L1128.91 250.016L995.981 -137.52L995.968 -137.534Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1061.83 -142.906L1192.08 246.131"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M618.277 392.505C618.277 392.505 691.511 241.349 738.771 182.501L906.029 175.83L912.994 209.092C912.994 209.092 838.367 307.068 810.518 392.505L1016.48 393.603V404.159L874.79 437.421L560.381 427.066V399.779L618.491 392.612L618.29 392.518L618.277 392.505Z"
          fill="white"
        />
        <path
          d="M758.369 415.01C758.369 415.01 794.979 312.144 881.542 195.147"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M912.887 209.092C912.887 209.092 838.26 307.068 810.41 392.505L1016.37 393.603V404.159L874.683 437.421L560.273 427.066V399.779L618.384 392.612C618.384 392.612 673.601 278.602 719.079 210.097"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.3099 45.1961C80.3099 45.1961 67.7715 1.28521 25.4815 7.95624C-16.8086 14.6273 -14.116 59.0338 -14.116 59.0338L80.3099 45.1961Z"
          fill="white"
        />
        <path
          d="M80.3099 45.1961C80.3099 45.1961 67.7715 1.28521 25.4815 7.95624C-16.8086 14.6273 -14.116 59.0338 -14.116 59.0338L80.3099 45.1961Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.5527 17.6145C49.5527 17.6145 58.3135 21.593 62.4929 29.8581"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.0003 49.7774C71.1458 68.3036 2.89496 77.0644 -12.2287 63.2133C-19.5963 56.5422 -4.16454 44.0977 19.7065 39.7173C28.0654 38.217 36.0224 37.2257 43.39 36.931C67.462 35.8326 84.2869 40.8158 80.0003 49.7774Z"
          fill="white"
        />
        <path
          d="M46.0685 45.1961C46.0685 52.8584 39.8931 59.0338 32.2442 59.0338C24.5953 59.0338 18.4199 52.8584 18.4199 45.1961C18.4199 37.5338 18.8218 41.4185 19.5184 39.7173C27.8772 38.217 35.8343 37.2257 43.2019 36.931C44.8897 39.2217 45.9882 42.1151 45.9882 45.1961H46.0819H46.0685Z"
          fill="white"
        />
        <path
          d="M45.5729 41.5123C45.8676 42.7045 46.0685 43.8968 46.0685 45.1961C46.0685 52.8584 39.8931 59.0338 32.2442 59.0338C24.5953 59.0338 18.4199 52.8584 18.4199 45.1961"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.6928 44.9952C42.6928 50.8759 37.9105 55.551 32.1504 55.551"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.0016 49.7774C85.5742 38.1232 55.2331 33.1534 19.6006 39.7173C-4.28379 44.0977 -19.595 56.5422 -12.2408 63.2133C2.88288 76.9572 71.1337 68.2902 79.9882 49.7774H80.0016Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M345.542 97.6669C345.542 97.6669 343.948 104.043 342.756 104.941C338.576 108.424 300.667 75.5641 300.667 75.5641L273.701 68.4912C273.701 68.4912 275.791 58.2301 280.466 52.0681C285.141 45.8927 318.081 46.2946 318.081 46.2946L345.448 72.3893V97.5865L345.542 97.6803V97.6669Z"
          fill="white"
        />
        <path
          d="M271.92 80.1453C271.92 80.1453 268.437 76.2606 269.629 74.1709C270.822 72.0812 273.702 68.5983 273.702 68.5983C273.702 68.5983 273.005 60.1322 275.698 58.0425C278.39 55.9528 293.501 45.8927 316.287 55.2562C342.85 66.1067 344.846 100.667 342.756 105.048C340.667 109.428 334.598 111.317 334.598 111.317C334.598 111.317 298.778 72.684 271.92 80.1453Z"
          fill="#FA7353"
        />
        <path
          d="M338.979 101.15C338.979 101.15 340.372 101.753 340.372 99.06C340.372 99.06 341.162 98.3635 341.363 97.5731"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M335.094 88.5042C335.094 88.5042 337.478 88.1024 337.09 85.517C337.09 85.517 338.188 84.6195 338.376 83.5211"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M316.742 67.7946C316.742 67.7946 318.43 66.6024 318.537 64.6064C318.537 64.6064 319.328 63.8161 319.528 62.5167"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M302.42 62.6105C302.42 62.6105 303.518 59.9179 303.411 58.2301C303.411 58.2301 304.014 56.636 304.309 55.7385"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M287.996 62.7176C287.996 62.7176 287.795 59.2348 289.094 57.7344C289.094 57.7344 289.697 56.0466 289.898 55.2429"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M328.529 76.7563C328.529 76.7563 330.217 75.256 330.324 73.8628C330.324 73.8628 330.82 72.8716 331.115 72.3759"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M276.555 65.303C276.555 65.303 275.564 63.8161 277.251 62.7176C277.251 62.7176 277.747 60.3332 277.948 59.5294"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M280.48 52.1619C280.48 52.1619 303.066 41.9008 323.856 55.7519C346.936 70.9827 345.543 97.6669 345.543 97.6669C345.543 97.6669 351.906 94.184 352.107 85.8117C352.308 73.769 354.893 67.098 345.543 53.046C336.193 39.0073 321.458 35.3235 309.134 37.507C296.703 39.7039 287.138 43.1868 285.156 45.8659C283.16 48.5584 280.48 52.0413 280.48 52.0413V52.1351V52.1619Z"
          fill="#FA7353"
        />
        <path
          d="M273.515 76.2607C271.519 79.047 269.041 92.8846 269.041 92.8846C261.379 107.325 261.673 117.586 270.635 129.535C279.583 141.484 288.344 139.394 288.344 139.394C288.344 139.394 311.023 138.001 320.775 124.056C320.775 124.056 327.245 114.197 337.788 109.415C337.386 106.83 336.89 103.936 336.193 101.056C332.51 85.4233 323.95 67.9955 304.352 64.9145C281.164 61.137 275.404 73.4744 273.408 76.2607H273.502H273.515Z"
          fill="#9D5B3A"
        />
        <path
          d="M283.655 83.8292C283.159 79.0469 275.403 77.7609 274.104 82.7307C274.104 82.7307 274.104 82.8379 274.197 82.7307C276.984 80.2391 281.056 81.3376 283.454 83.9229C283.454 83.9229 283.655 83.9229 283.547 83.8292H283.641H283.655Z"
          fill="#010205"
        />
        <path
          d="M300.775 83.521C306.642 79.3416 309.83 82.3288 315.296 85.008C315.296 85.008 315.497 85.008 315.39 84.9142C313.501 82.7173 311.21 80.3329 308.223 79.7301C305.142 79.0335 302.155 80.9223 300.561 83.5076C300.561 83.6014 300.561 83.7086 300.761 83.5076L300.775 83.521Z"
          fill="#010205"
        />
        <path
          d="M279.086 97.5731C279.086 97.5731 276.501 99.261 276.594 102.154C276.688 105.048 281.765 107.928 281.765 107.928"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M331.317 106.334C331.317 106.334 340.573 101.458 340.667 109.428C340.761 117.399 333.701 126.454 324.753 121.578C315.805 116.702 331.317 106.347 331.317 106.347V106.334Z"
          fill="#9D5B3A"
        />
        <path
          d="M336.099 113.206C336.099 113.206 333.714 109.616 327.244 113.701"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M273.513 76.2606C273.513 76.2606 281.269 67.7946 294.611 68.1965C307.94 68.4912 317.196 73.0725 324.256 83.6283C324.256 83.6283 322.568 84.9276 323.559 88.7052C324.658 92.4962 329.038 97.265 327.042 98.8591C325.046 100.453 321.67 101.444 322.26 103.936C323.157 107.62 321.858 106.428 320.371 109.013C318.576 112.107 322.26 115.885 316.594 112.603C316.995 112.898 321.563 116.89 322.059 112.201C322.26 109.911 322.756 110.312 324.939 109.12C327.122 107.928 325.435 104.137 328.02 104.043C330.605 104.043 334.584 107.231 336.272 101.257C332.588 85.6242 324.028 68.1965 304.43 65.1155C281.242 61.3379 275.482 73.6753 273.486 76.4616V76.2606H273.513Z"
          fill="#010205"
        />
        <path
          d="M284.859 114.987C283.466 117.372 280.974 122.556 284.859 123.454C289.936 124.646 295.602 115.188 295.602 115.188C293.512 115.684 288.342 115.282 284.859 114.987Z"
          fill="white"
        />
        <path
          d="M284.86 114.987C282.676 114.786 281.082 114.586 281.082 114.586"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M284.859 114.987C283.466 117.372 280.974 122.556 284.859 123.454C289.936 124.646 295.602 115.188 295.602 115.188C293.512 115.684 288.342 115.282 284.859 114.987Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M301.177 98.7653C302.557 98.7653 303.669 97.6535 303.669 96.2737C303.669 94.894 302.557 93.7821 301.177 93.7821C299.797 93.7821 298.686 94.894 298.686 96.2737C298.686 97.6535 299.797 98.7653 301.177 98.7653Z"
          fill="#010205"
        />
        <path
          d="M276.888 95.1753C278.254 95.1753 279.38 94.0635 279.38 92.6837C279.38 91.3039 278.268 90.1921 276.888 90.1921C275.508 90.1921 274.396 91.3039 274.396 92.6837C274.396 94.0635 275.508 95.1753 276.888 95.1753Z"
          fill="#010205"
        />
        <path
          d="M271.623 75.6578C272.815 74.0637 283.358 61.6191 304.563 64.9011C324.161 68.0892 332.721 85.4098 336.404 101.043C337.007 103.628 337.101 104.525 337.101 104.525"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M263.654 110.219C263.654 110.219 255.992 117.385 260.962 122.569C265.931 127.753 275.295 120.573 275.295 120.573"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M274.506 124.15C272.818 123.654 272.122 120.56 272.323 119.971C273.019 118.176 276.998 116.983 278.096 117.278C279.69 117.774 280.588 120.466 280.186 121.658C279.784 122.851 275.31 124.351 274.52 124.043V124.137L274.506 124.15Z"
          fill="#010205"
        />
        <path
          d="M153.93 71.2774L283.278 13.6226"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M151.346 48.3709L219.998 14.0245"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M169.846 71.2775V100.453"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M333.621 15.0157V36.422"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M540.072 23.9774V259.178"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.986 112.697V52.5503"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SupportPageIllustration;
